export function isValidNif(nif: string): boolean {
  if (nif.length !== 9) return false;

  const nifArray: string[] = nif.split('');

  const validFirstDigit = ['1', '2', '3', '5', '6', '8', '9'].includes(nifArray[0]);

  if (!validFirstDigit) return false;

  const check1 = parseInt(nif.substr(0, 1), 10) * 9;
  const check2 = parseInt(nif.substr(1, 1), 10) * 8;
  const check3 = parseInt(nif.substr(2, 1), 10) * 7;
  const check4 = parseInt(nif.substr(3, 1), 10) * 6;
  const check5 = parseInt(nif.substr(4, 1), 10) * 5;
  const check6 = parseInt(nif.substr(5, 1), 10) * 4;
  const check7 = parseInt(nif.substr(6, 1), 10) * 3;
  const check8 = parseInt(nif.substr(7, 1), 10) * 2;

  const total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8;

  const lastDigit = parseInt(nif.substr(8, 1), 10);

  const division = Math.trunc(total / 11);

  const module = total - division * 11;

  let comparator = 0;

  if (module === 0 || module === 1) {
    comparator = 0;
  } else {
    comparator = 11 - module;
  }

  if (lastDigit !== comparator) {
    return false;
  }

  return true;
}
