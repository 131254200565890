import moment from 'moment';

export const getTimeDifference = (
  departTime: string,
  arrivalTime: string,
  arrivalDay: number,
  forShow = true,
): string | number => {
  const depDate = moment(departTime, 'HH:mm');
  const arrDate = moment(arrivalTime, 'HH:mm').add(arrivalDay, 'day');

  if (forShow) {
    const hours = moment.duration(arrDate.diff(depDate)).get('hour');
    const minutes = moment.duration(arrDate.diff(depDate)).get('minute');
    const dur = Math.trunc(arrDate.diff(depDate) / 1000 / 60 / 60);
    const dur2 = Math.round((arrDate.diff(depDate) / 1000 / 60 / 60 - dur) * 60);
    return `${hours ? `${dur}h` : ``} ${dur2}m`;
  }

  return arrDate.diff(depDate, 'ms');
};

export const getISODate = (
  date: moment.Moment | null,
  time: string,
  day = 0,
  timezone: string | null = null,
): string => {
  const [hours, minutes] = time.split(':');

  // Preserva a data original e define a hora exata sem conversão de timezone
  return moment(date)
    .clone()
    .add(day, 'day')
    .set({
      hour: parseInt(hours, 10),
      minute: parseInt(minutes, 10),
      second: 0,
      millisecond: 0,
    })
    .format('YYYY-MM-DDTHH:mm:00');
};

export const timeInHours = (duration: string | any) => {
  const hours = Math.floor(moment.duration(duration).asMinutes() / 60);
  const minutes = Math.floor(moment.duration(duration).asMinutes() % 60);
  return `${hours}h ${minutes}m`;
};

export const timeInMinutes = (duration: string | any) => {
  const minutes = Math.floor(moment.duration(duration).asMinutes());
  return minutes;
};
