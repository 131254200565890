import React from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';

import { AppViews, AppViewsEn } from 'endpoints';

import { AuthGuard, GuestGuard } from 'guards';

import loadable from '@loadable/component';

import withTracker from 'utils/withTracker';

export type AppRoute = RouteProps & {
  guard?: React.ComponentType;
  routes?: AppRoute[];
};

const appRoutes: AppRoute[] = [
  { exact: true, path: AppViews.HOME, component: withTracker(loadable(() => import('screens/Home'))) },

  {
    exact: true,
    path: AppViews.STOPS_INFO,
    component: loadable(() => import('components/StopsInformation/stops-information.component')),
  },
  { exact: true, path: AppViews.FAQS, component: withTracker(loadable(() => import('components/faqs'))) },
  { exact: true, path: AppViews.HELP, component: withTracker(loadable(() => import('components/static_pages/Help'))) },
  {
    exact: true,
    path: `${AppViews.STATIC_PAGE}/:slug`,
    component: withTracker(loadable(() => import('components/static_pages/Page'))),
  },
  {
    exact: true,
    path: AppViews.SEARCH,
    component: withTracker(loadable(() => import('components/searchResult/SearchResult'))),
  },
  {
    exact: true,
    path: `${AppViews.CHECKOUT}/:id`,
    component: withTracker(loadable(() => import('components/checkout/checkout.component'))),
  },
  {
    exact: true,
    path: `${AppViews.PAYMENT_LANDING_PAGE}/:id`,
    component: withTracker(loadable(() => import('components/checkout/landing_page'))),
  },
  {
    exact: true,
    path: AppViews.SIGN_IN,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/SignIn'))),
  },
  {
    exact: true,
    path: AppViews.SIGN_UP,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/SignUp'))),
  },
  {
    exact: true,
    path: AppViews.SIGN_IN_MOBILE,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/SignInMobile'))),
  },

  {
    exact: true,
    path: AppViews.CONFIRMATION,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/SignUp/Confirmation'))),
  },
  {
    exact: true,
    path: AppViews.CONFIRMATIONWEB,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/webtocase/confirmationweb'))),
  },
  {
    exact: true,
    path: AppViews.DADOSCASO,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/webtocase/dadoscaso'))),
  },
  {
    exact: true,
    path: AppViews.SIGN_UP_COMPLETED,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/SignUp/SignUpCompleted'))),
  },
  {
    exact: true,
    path: AppViews.FORGOT_PASSWORD,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/ForgotPassword'))),
  },
  {
    exact: true,
    path: AppViews.MANAGE_YOUR_BOOKING,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/ManageYourBooking'))),
  },
  {
    exact: true,
    path: AppViews.BOOKINGS,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/users/bookings/bookingTickets/bookingTicketsGeneral'))),
  },

  {
    exact: true,
    path: AppViews.USER_BOOKINGS,
    guard: AuthGuard,
    component: withTracker(loadable(() => import('components/users/bookings'))),
  },
  {
    exact: true,
    path: `${AppViews.USER_BOOKINGS}/:bookingId`,
    guard: AuthGuard,
    component: withTracker(loadable(() => import('components/users/bookings/bookingTickets'))),
  },
  {
    exact: true,
    path: AppViews.USER_PROFILE,
    guard: AuthGuard,
    component: withTracker(loadable(() => import('components/users/Profile'))),
  },
  {
    exact: true,
    path: AppViews.USER_PAYMENT_METHODS,
    guard: AuthGuard,
    component: withTracker(loadable(() => import('components/users/paymentMethods'))),
  },
  {
    exact: true,
    path: AppViews.USER_ADD_PAYMENT_METHODS,
    guard: AuthGuard,
    component: withTracker(loadable(() => import('components/users/paymentMethods/AddPaymentMethod'))),
  },
  {
    exact: true,
    path: `${AppViews.PAYMENT_LINK}/:id`,
    component: withTracker(loadable(() => import('components/checkout/checkout.component'))),
  },
  {
    exact: true,
    path: AppViews.ALUGUERES,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('modules/alugueres/home'))),
  },
  {
    exact: true,
    path: AppViews.WEBTOCASE,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/webtocase'))),
  },

  // rotas antigas em inglês, a ideia é ir tirando-as aos poucos sem afetar clientes que já tem algo armazenado no favoritos etc.

  {
    exact: true,
    path: AppViewsEn.STOPS_INFO,
    component: loadable(() => import('components/StopsInformation/stops-information.component')),
  },
  { exact: true, path: AppViewsEn.FAQS, component: withTracker(loadable(() => import('components/faqs'))) },
  {
    exact: true,
    path: AppViewsEn.HELP,
    component: withTracker(loadable(() => import('components/static_pages/Help'))),
  },
  {
    exact: true,
    path: `${AppViewsEn.STATIC_PAGE}/:slug`,
    component: withTracker(loadable(() => import('components/static_pages/Page'))),
  },
  {
    exact: true,
    path: AppViewsEn.SEARCH,
    component: withTracker(loadable(() => import('components/searchResult/SearchResult'))),
  },
  {
    exact: true,
    path: `${AppViewsEn.CHECKOUT}/:id`,
    component: withTracker(loadable(() => import('components/checkout/checkout.component'))),
  },
  {
    exact: true,
    path: `${AppViewsEn.PAYMENT_LANDING_PAGE}/:id`,
    component: withTracker(loadable(() => import('components/checkout/landing_page'))),
  },
  {
    exact: true,
    path: AppViewsEn.SIGN_IN,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/SignIn'))),
  },
  {
    exact: true,
    path: AppViewsEn.SIGN_UP,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/SignUp'))),
  },
  {
    exact: true,
    path: AppViewsEn.SIGN_IN_MOBILE,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/SignInMobile'))),
  },

  {
    exact: true,
    path: AppViewsEn.CONFIRMATION,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/SignUp/Confirmation'))),
  },
  {
    exact: true,
    path: AppViewsEn.CONFIRMATIONWEB,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/webtocase/confirmationweb'))),
  },
  {
    exact: true,
    path: AppViewsEn.DADOSCASO,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/webtocase/dadoscaso'))),
  },
  {
    exact: true,
    path: AppViewsEn.SIGN_UP_COMPLETED,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/SignUp/SignUpCompleted'))),
  },
  {
    exact: true,
    path: AppViewsEn.FORGOT_PASSWORD,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/ForgotPassword'))),
  },
  {
    exact: true,
    path: AppViewsEn.MANAGE_YOUR_BOOKING,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/auth/ManageYourBooking'))),
  },
  {
    exact: true,
    path: AppViewsEn.BOOKINGS,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/users/bookings/bookingTickets/bookingTicketsGeneral'))),
  },

  {
    exact: true,
    path: AppViewsEn.USER_BOOKINGS,
    guard: AuthGuard,
    component: withTracker(loadable(() => import('components/users/bookings'))),
  },
  {
    exact: true,
    path: `${AppViewsEn.USER_BOOKINGS}/:bookingId`,
    guard: AuthGuard,
    component: withTracker(loadable(() => import('components/users/bookings/bookingTickets'))),
  },
  {
    exact: true,
    path: AppViewsEn.USER_PROFILE,
    guard: AuthGuard,
    component: withTracker(loadable(() => import('components/users/Profile'))),
  },
  {
    exact: true,
    path: AppViewsEn.USER_PAYMENT_METHODS,
    guard: AuthGuard,
    component: withTracker(loadable(() => import('components/users/paymentMethods'))),
  },
  {
    exact: true,
    path: AppViewsEn.USER_ADD_PAYMENT_METHODS,
    guard: AuthGuard,
    component: withTracker(loadable(() => import('components/users/paymentMethods/AddPaymentMethod'))),
  },
  {
    exact: true,
    path: `${AppViewsEn.PAYMENT_LINK}/:id`,
    component: withTracker(loadable(() => import('components/checkout/checkout.component'))),
  },
  {
    exact: true,
    path: AppViewsEn.ALUGUERES,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('modules/alugueres/home'))),
  },
  {
    exact: true,
    path: AppViewsEn.WEBTOCASE,
    guard: GuestGuard,
    component: withTracker(loadable(() => import('components/webtocase'))),
  },
];

const renderRoutes = (routes: AppRoute[]) =>
  routes.map((route) => {
    const Guard = route.guard || React.Fragment;
    const Component = route.component || React.Fragment;

    return (
      <Route
        key={`route-${route.path}`}
        path={route.path}
        exact={route.exact}
        render={(props) => (
          <Guard>
            {route.routes ? (
              renderRoutes(route.routes)
            ) : (
              <Component {...(route.path === AppViews.HELP && { key: props.location.key })} {...props} />
            )}
          </Guard>
        )}
      />
    );
  });

const Router: React.VFC = () => (
  <React.Suspense fallback={null}>
    <Switch>{renderRoutes(appRoutes)}</Switch>
  </React.Suspense>
);

export default Router;
