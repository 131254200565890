import { GetPaymentResponse, EnhancedPaymentResponse } from '../store/types/payments';


export function enhancePayment(payment: GetPaymentResponse): EnhancedPaymentResponse {
  return {
    ...payment,
    showInvoiceRedirect: () => {
      return payment.trips.some(trip => 
        trip.tickets.some(ticket => ticket.externalInvoice === true)
      );
    },
    getOutwardTrips: () => {
      return payment.trips.filter(trip => trip.return === false);
    },
    getReturnTrips: () => {
      return payment.trips.filter(trip => trip.return === true);
    },
    outwardTripBookingId: () => {
      return enhancePayment(payment).getOutwardTrips()[0]?.tickets[0].bookingId || null;
    },
    outwardTripHasInvoiceRedirect: () => {
      return enhancePayment(payment).getOutwardTrips()[0]?.tickets[0].externalInvoice || false;
    },
    returnTripBookingId: () => {
      return enhancePayment(payment).getReturnTrips()[0]?.tickets[0].bookingId || null;
    },
    returnTripHasInvoiceRedirect: () => {
      return enhancePayment(payment).getReturnTrips()[0]?.tickets[0].externalInvoice || false;
    },
    getInvoiceRedirectUrl: () => {
      const allTickets = payment.trips.flatMap(trip => trip.tickets);
      return allTickets.find(ticket => ticket.invoiceUrl !== null)?.invoiceUrl || null;
    },
  };
}
