import 'bootstrap/dist/css/bootstrap.min.css';
import GA from 'ga-4-react';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import 'typeface-poppins';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store, { persistor } from './store';

if (['production'].includes(process.env.REACT_APP_ENV as string)) {
  const tagManagerArgs = {
    gtmId: String(process.env.REACT_APP_GTM_ID),
  };

  TagManager.initialize(tagManagerArgs);
}

export const ga = ['staging', 'production'].includes(process.env.REACT_APP_ENV as string)
  ? new GA(process.env.REACT_APP_GA_ID as string)
  : null;

(async () => {
  try {
    if (ga && ['staging', 'production'].includes(process.env.REACT_APP_ENV as string) && process.env.REACT_APP_GA_ID) {
      await ga.initialize();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('[GA] Error: ', err);
  }
  const CACHE_TIME = 24 * 60 * 60 * 1000; // 1 day

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: CACHE_TIME,
        cacheTime: CACHE_TIME,
        retry: 1,
      },
    },
  });

  const localStoragePersistor = createWebStoragePersistor({ storage: window.localStorage });

  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
  });

  const rootElement = document.getElementById('root');

  const Index = (
    <React.StrictMode>
      <Suspense fallback={null}>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <ToastContainer />
            <QueryClientProvider client={queryClient}>
              <App useSuspense />
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </Suspense>
    </React.StrictMode>
  );

  if (rootElement?.hasChildNodes()) {
    ReactDOM.hydrate(Index, rootElement);
  } else {
    ReactDOM.render(Index, rootElement);
  }
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
